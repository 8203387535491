const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/general/gen025.svg",
      },
      // {
      // sectionTitle: "จัดการระบบ",
      // route: "/manage-system",
      // svgIcon: "media/icons/duotune/ecommerce/ecm007.svg",
      // icon: "bi bi-briefcase-fill",
      // sub: [
      //     {
      //       heading: "กำหนดงวดบัญชี",
      //       route: "/manage-system/op1",
      //     },
      //     {
      //       heading: "ปรับปรุงยอดยกมา",
      //       route: "/manage-system/op2",
      //     },
      //     {
      //       heading: "คำนวณสร้างยอดยกไป",
      //       route: "/manage-system/op3",
      //     },
      // ],
      // },
      // ---
      {
        sectionTitle: "warehouse",
        route: "/warehouse",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        icon: "bi bi-box-seam",
        sub: [
          {
            heading: "branch",
            route: "/warehouse/BRANCH",
          },
          {
            heading: "warehouse",
            route: "/warehouse/WH1",
          },
          {
            heading: "managevan",
            route: "/warehouse/VAN",
          },
          {
            heading: "manageunit",
            route: "/warehouse/UNIT",
          },
          // {
          //     heading: "managemainproduct",
          //     route: "/warehouse/WH2"
          // },
          // { // heading: "managesubproduct",
          //     heading: "manageproducttype",
          //     route: "/warehouse/WH2SUB"
          // },
          // {
          //     heading: "manageproducttype",
          //     route: "/warehouse/WH3"
          // },
          {
            heading: "addproduct",
            route: "/warehouse/WH4",
          },
          {
            heading: "productgroup",
            route: "/warehouse/WH4-2",
          },
          // ปิดก่อน Item Group
          {
            heading: "PRICE LIST",
            route: "/warehouse/PRICELIST",
          },
          // {
          // heading: "จัดการสินค้าหมดอายุ",
          // route: "/warehouse/WH5",
          // },
          // {
          // heading: "ตัดสินค้าหมดอายุ / ชำรุด / สูญหาย",
          // route: "/warehouse/WH6-1",
          // },
          // {
          // heading: "อนุมัติตัดสินค้าหมดอายุ / ชำรุด / สูญหาย",
          // route: "/warehouse/WH6-2",
          // },
          // {
          // heading: "เพิ่มจำนวนสินค้าเข้าระบบ",
          // route: "/warehouse/WH6-3",
          // },
          // {
          // heading: "อนุมัติเพิ่มจำนวนสินค้าเข้าระบบ",
          // route: "/warehouse/WH6-4",
          // },
          // {
          // heading: "Report",
          // route: "/warehouse/WH7",
          // },
        ],
      },
      // ---
      // {
      // sectionTitle: "ซื้อ",
      // route: "/purchase",
      // svgIcon: "media/icons/duotune/technology/teh004.svg",
      // icon: "bi bi-cart4",
      // sub: [
      //     {
      //       heading: "เพิ่มผู้ขาย / ผู้รับเงิน",
      //       route: "/purchase/PU1",
      //     },
      //     {
      //       heading: "สร้างใบสั่งซื้อสินค้า",
      //       route: "/purchase/PU2-1",
      //     },
      //     {
      //       heading: "อนุมัติใบสั่งซื้อสินค้า",
      //       route: "/purchase/PU2-2",
      //     },
      //     {
      //       heading: "รับสินค้า - รอดำเนินการ",
      //       route: "/purchase/PU2-3",
      //     },
      //     {
      //       heading: "รับสินค้า - เข้าคลังแล้ว",
      //       route: "/purchase/PU2-4",
      //     },
      // ],
      // },
      // ---

      // ---
      {
        sectionTitle: "customer",
        route: "/CUSTOMER",
        svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
        icon: "fas fa-user",
        sub: [
          {
            heading: "addroutes",
            route: "/CUSTOMER/route/index",
          },
          {
            heading: "addbusinesstype",
            route: "/CUSTOMER/store_type/index",
          },
          // {
          //     heading: "addsector",
          //     route: "/CUSTOMER/sector/index"
          // },
          // {
          //     heading: "addzone",
          //     route: "/CUSTOMER/zone/index"
          // },
          {
            heading: "managediscount",
            route: "/CUSTOMER/discount/index",
          },

          // {
          //     heading: "จัดการกลุ่มส่วนลด",
          //     route: "/CUSTOMER/groupdiscount/index"
          // },
          {
            heading: "managecustomer",
            route: "/CUSTOMER/index",
          },
        ],
      },
      {
        sectionTitle: "selling",
        route: "/sale",
        svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
        icon: "bi bi-cash-coin",
        sub: [
          // {
          // heading: "เพิ่มผู้ซื้อ / ผู้จ่ายเงิน",
          // route: "/sale/SA1",
          // },
          // {
          // heading: "สร้างใบขายส่ง",
          // route: "/sale/SA2-1",
          // },
          // {
          //     heading: "wholesaleapprove",
          //     route: "/sale/SA2-2"
          // },
          // {
          // heading: "สร้างใบขายปลีก",
          // route: "/sale/SA3-1",
          // },
          //     {
          //       heading: "อนุมัติขายปลีก",
          //       route: "/sale/SA3-2",
          //     },
          //     // {
          //     //   heading: "สร้างใบโอนสินค้าออก",
          //     //   route: "/sale/SA4-1",
          //     // },
          //     // {
          //     //   heading: "อนุมัติโอนสินค้าออก",
          //     //   route: "/sale/SA4-2",
          //     // },
          {
            heading: "POS",
            route: "/sale/SA5",
          },
          // {
          //     heading: "รับสินค้าเข้าคลัง",
          //     route: "/sale/RECEIVE"
          // },
          {
            heading: "importwarehouse",
            route: "/sale/SA4-0",
          },
          // {
          //   heading: "Transferproduct",
          //   route: "/sale/SA4-1",
          // },
          // {
          //     heading: "receipt",
          //     route: "/sale/SA4-1RECEIVE"
          // },
          {
            heading: "transferproducttovan",
            route: "/sale/SA4-1BETWEEN",
          },
          // {
          //   heading: "transferproducttovandirect",
          //   route: "/sale/SA4-1DIRECT",
          // },
          // {
          //   heading: "Transfervan",
          //   route: "/sale/SA4-1VAN",
          // },
          // {

          //     heading: "อนุมัตโอนสินค้า",
          //     route: "/sale/SA4-2"
          // },
          {
            heading: "salessummarycashvan",
            route: "/sale/daily-retail-summary-cashvan",
          },
          // {
          //   heading: "salessummarypreorder",
          //   route: "/sale/daily-retail-summary-preorder",
          // },

          //     {
          //       heading: "สรุปขายปลีกประจำวัน",
          //       route: "/sale/daily-retail-summary",
          //     },
          // {
          // heading: "ราคาขาย / ป้ายราคา",
          // route: "/sale/price-tag",
          // },
          // {
          //   heading: "adjustwarhouse",
          //   route: "/sale/AJ4-0",
          // },
          // {
          //   heading: "CREDIT NOTE",
          //   route: "/sale/SA9",
          // },
        ],
      },
      {
        sectionTitle: "รายงาน",
        route: "/report",
        svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
        icon: "bi bi-file-bar-graph",
        sub: [
          {
            heading: "salesDialyReport",
            route: "/report/reportDialySales",
          },
          // {
          //   heading: "showShopList",
          //   route: "/report/report2",
          // },
          {
            sectionTitle: "salesAnalysisReport",
            sub: [
              {
            heading: "รายงาน daily Call Strike Report",
            route: "/report/report7",
          },
              // {
              //   heading: "salesAnalysisReport2",
              //   route: "/report/report4-2",
              // },
              // {
              //   heading: "salesAnalysisReport3",
              //   route: "/report/report4-3",
              // },
              // {
              //   heading: "salesAnalysisReport4",
              //   route: "/report/report4-4",
              // },
              // {
              //   heading: "salesAnalysisReport5",
              //   route: "/report/report4-5",
              // },
              // {
              //   heading: "salesAnalysisReport6",
              //   route: "/report/report4-6",
              // },
              // {
              //   heading: "salesAnalysisReport7",
              //   route: "/report/report4-7",
              // },
              // {
              //   heading: "salesAnalysisReport8",
              //   route: "/report/report4-8",
              // },
              // {
              //   heading: "salesAnalysisReport9",
              //   route: "/report/report4-9",
              // },
            ],
          },

          // {
          //   sectionTitle: "รายงานตรวจสอบด้านบัญชี",
          //   // route: "/report",
          //   // svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
          //   // icon: "bi bi-file-bar-graph",
          //   sub: [
          //     {
          //       heading: "รายงานภาษีขาย",
          //       route: "/report/report5-1",
          //     },
          //     {
          //       heading: "รายงานภาษีซื้อ",
          //       route: "/report/report5-2",
          //     },
          //     {
          //       heading: "รายงานหนี้ค้างชำระตามสายวิ่ง/ลูกค้า/ใบกำกับ",
          //       route: "/report/report5-3",
          //     },
          //   ],
          // },
          // {
          //   heading: "รายงานเป้าขายเทียบยอดขาย",
          //   route: "/report/report6",
          // },
          // {
          //   heading: "รายงาน daily Call Strike Report",
          //   route: "/report/report7",
          // },
          // {
          //   sectionTitle: "รายงานตัวสินค้าที่ขายลงร้านค้าแล้วและยังไม่ได้ขาย",
          //   // route: "/report",
          //   // svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
          //   // icon: "bi bi-file-bar-graph",
          //   sub: [
          //     {
          //       heading: "รายงานของแถมแต่ละเดือนตามพนักงานขาย",
          //       route: "/report/report12-1",
          //     },
          //     {
          //       heading: "รายงานลูกค้าที่ซื้อสินค้าในแต่ละเดือนตามพนักงานขาย",
          //       route: "/report/report12-2",
          //     },
          //   ],
          // },
          // {
          //   sectionTitle:
          //     "ดูตามสินค้าสาขาช่องทางการขายและรายชื่อร้านค้าและยังไม่ได้ขาย",
          //   // route: "/report",
          //   // svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
          //   // icon: "bi bi-file-bar-graph",
          //   sub: [
          //     // {
          //     //   heading: "ลูกค้า",
          //     //   route: "/report/report13-1",
          //     // },
          //     // {
          //     //   heading: "สินค้า",
          //     //   route: "/report/report13-2",
          //     // },
          //     // {
          //     //   heading: "รายงานสรุปสต็อกสินค้าคงเหลือ คลังสินค้า",
          //     //   route: "/report/report13-3",
          //     // },
          //     // {
          //     //   heading: "รายงานสรุปสต็อกสินค้าคงเหลือ รถสินค้า",
          //     //   route: "/report/report13-4",
          //     // },
          //   ],
          // },
        ],
      },
      // ---
      // {
      //     sectionTitle: "customer",
      //     route: "/CUSTOMER",
      //     svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
      //     icon: "fas fa-user",
      //     sub: [

      //         {
      //             heading: "addroutes",
      //             route: "/CUSTOMER/route/index"
      //         },
      //         {
      //             heading: "addbusinesstype",
      //             route: "/CUSTOMER/store_type/index"
      //         },
      //         // {
      //         //     heading: "addsector",
      //         //     route: "/CUSTOMER/sector/index"
      //         // },
      //         // {
      //         //     heading: "addzone",
      //         //     route: "/CUSTOMER/zone/index"
      //         // },
      //         {
      //             heading: "adddiscount",
      //             route: "/CUSTOMER/discount/index"
      //         }, {
      //             heading: "addcustomer",
      //             route: "/CUSTOMER/index"
      //         },
      //     ]
      // },

      // ---
      // {
      // sectionTitle: "โอนสินค้า",
      // route: "/transfer-product",
      // svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
      // icon: "bi bi-arrow-left-right",
      // sub: [
      //     {
      //       heading: "เพิ่มผู้รับโอน",
      //       route: "/transfer-product/TP1",
      //     },
      //     {
      //       heading: "โอนสินค้าออก",
      //       route: "/transfer-product/TP2-1",
      //     },
      //     {
      //       heading: "อนุมัติการโอนสินค้าออก",
      //       route: "/transfer-product/TP2-2",
      //     },
      //     {
      //       heading: "รับโอนสินค้าเข้า",
      //       route: "/transfer-product/TP3-1",
      //     },
      //     {
      //       heading: "อนุมัติการโอนสินค้าเข้า",
      //       route: "/transfer-product/TP3-2",
      //     },
      // ],
      // },
      // ---
      // {
      // sectionTitle: "รายงาน",
      // route: "/report",
      // svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
      // icon: "bi bi-file-bar-graph",
      // sub: [
      //     {
      //       heading: "สรุปยอดขาย / ลูกค้า",
      //       route: "/report/summary-sales-customer-report",
      //     },
      //     {
      //       heading: "ยอดขาย Weekly",
      //       route: "/report/summary-weekly-report",
      //     },
      //     {
      //       heading: "สินค้าขายดี",
      //       route: "/report/summary-best-seller-report",
      //     },
      //     {
      //       heading: "สรุป Transaction",
      //       route: "/report/summary-transaction-report",
      //     },
      //     {
      //       heading: "รายงานคลังสินค้า",
      //       route: "/report/summary-warehouse-report",
      //     },
      // ],
      // },
      // ---
      // {
      //   sectionTitle: "discouts",
      //   route: "/setting",
      //   svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
      //   icon: "bi bi-currency-exchange",
      //   sub: [
      //     {
      //       heading: "managediscounts",
      //       route: "/promotion/discount/index",
      //     },
      //     {
      //       heading: "managedistrict",
      //       route: "/promotion/district/index",
      //     },
      //     {
      //       heading: "managepromotion",
      //       route: "/promotion/promotion/index",
      //     },
      //   ],
      // },
      {
        sectionTitle: "setting",
        route: "/config",
        svgIcon: "media/icons/duotune/ecommerce/ecm002.svg",
        icon: "fas fa-cog",

        sub: [
          {
            heading: "managegroupuser",
            route: "/config/user-group/index",
          },
          {
            heading: "manageuser",
            route: "/config/user/index",
          },
          {
            heading: "managetabletuser",
            route: "/config/user-tablet/index",
          },
          {
            heading: "managecompany",
            route: "/config/company/index",
          },
          {
            heading: "importcustomer",
            route: "/config/customer/index",
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
